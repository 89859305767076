import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  private readonly secAvailable: boolean;

  constructor() {
    this.secAvailable = inject(SecAvailable).valueOf();
  }

  async getTimesLaunched(): Promise<number> {
    return await this.getInt(Key.timesLaunched, false);
  }

  async setTimesLaunched(timesLaunched: number) {
    await this.setItem(Key.timesLaunched, timesLaunched.toString(), false);
  }

  async setIsIdVerified(onboarded: boolean) {
    await this.setBoolean(Key.isIdVerified, onboarded);
  }

  async getIsIdVerified(): Promise<boolean> {
    return Boolean(await this.getItem(Key.isIdVerified));
  }

  async setHasFakeBio(hasFakeBio: boolean) {
    await this.setBoolean(Key.hasFakeBio, hasFakeBio);
  }

  async getHasFakeBio(): Promise<boolean> {
    return Boolean(await this.getItem(Key.hasFakeBio));
  }

  private async setBoolean(key: Key, verified: boolean, secure = this.secAvailable) {
    if (verified) {
      await this.setItem(key, String(verified), secure);
    } else {
      await this.remove(key, secure);
    }
  }

  private async getInt(key: Key, secure = this.secAvailable) {
    const item: string | null = await this.getItem(key, secure);
    const timesLaunched = item ? parseInt(item, 10) : 0;
    return timesLaunched ? timesLaunched : 0;
  }

  private async setItem(key: Key, value: string, secure = this.secAvailable) {
    // console.log('Setting', key, ': ', value);
    return await (secure ?
      SecureStoragePlugin.set({
        key,
        value,
      })
    : Preferences.set({ key, value }));
  }

  private async getItem(key: Key, secure = this.secAvailable): Promise<string | null> {
    let value: string | null;
    if (secure) {
      try {
        ({ value } = await SecureStoragePlugin.get({ key }));
      } catch (e) {
        value = null;
      }
    } else {
      ({ value } = await Preferences.get({ key }));
    }
    // console.log('Got', key, ': ', value);
    return value;
  }

  private async remove(key: Key, secure = this.secAvailable): Promise<void> {
    try {
      await (secure ? SecureStoragePlugin.remove({ key }) : Preferences.remove({ key }));
    } catch (e) {
      console.error(`$Storage key: '${key}' did not exist`);
    }
  }

  async resetApp() {
    console.log('RESET app');
    await Preferences.clear();
    for (const key of Object.keys(Key)) {
      // console.log(key, Key[key as keyof typeof Key]);
      await this.remove(Key[key as keyof typeof Key], this.secAvailable);
    }
  }
}

export enum Key {
  emailVerified = 'email-verified',
  isIdVerified = 'isIdVerified',
  timesLaunched = 'times-launched',
  hasFakeBio = 'has-fake-bio',
  authenticatedUser = 'authenticated-user',
}

@Injectable({
  providedIn: 'root',
})
export class SecAvailable extends Boolean {
  constructor() {
    super(Capacitor.isNativePlatform());
  }
}
